import AJAX from "@core/ajax";
// import EMITTER from "@core/emitter";

const STORAGE = "language-preferences";

//
// Exemple of content in storage : {country: "CA", region: "CA-QC"}
//

class GeoLocalization {
  constructor() {
    this._country = null;
    this._region = null;
    this._ready = false;

    this._read();
  }


  fetch() {
    if( this._ready ) return Promise.resolve();

    return AJAX
      .post("jampcare_geolocate")
      .then(response => {
        this._save(response);
        this._read(response);
        return response;
      });
  }

  _read(value) {
    if( !value ) {
      const storage = sessionStorage.getItem(STORAGE);
      if( !storage ) return;

      value = JSON.parse(storage);
    }

    this._country = value.country;
    this._region = value.region;
    this._ready = true;
  }
  _save(value) {
    if( !value ) {
      value = {
        country: this._country,
        region: this._region,
      };
    }
    sessionStorage.setItem(STORAGE, JSON.stringify(value));
  }

  // getter - setter
  get country() { return this._country; }
  set country(value) {
    if( this._country === value ) return;

    this._country = value;
    this._save();
  }


  get region() { return this._region; }
  set region(value) {
    if( this._region === value ) return;

    this._region = value;
    this._save();
  }

  // get currency() { return this._currency; }
  // set currency(value) {
  //   if( this._currency === value ) return;

  //   this._currency = value;
  //   this._save();
  // }

  // get units() { return this._units; }
  // set units(value) {
    //   if( this._units === value ) return;

    //   this._units = value;
    //   this._save();
  // }

  get ready() { return this._ready; }
}

const SINGLETON = new GeoLocalization();
export default SINGLETON;
